import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import {
  ATHLETE,
  ATHLETESEARCH,
  BRAND,
  BRANDSEARCH,
  PROMOTION,
  PROMOTIONSEARCH,
  propTypes,
  SPORTTEAM,
} from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Modal,
} from '../../../../components';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import Icons from '../../../../components/Icons/Icons';
import css from './TopbarDesktop.module.css';
import { ensureCurrentUser, ensureOwnListing } from '../../../../util/data';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';
import { createSlug } from '../../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../../util/routes';

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};
const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const checkCurrentUserPublicData = currentUser => {
  const isBio = currentUser?.attributes?.profile?.bio;
  const { companyName, website, myCompanyInterestValues } =
    currentUser?.attributes?.profile?.publicData || {};
  const allFieldsExist =
    companyName && website && (myCompanyInterestValues && myCompanyInterestValues?.length > 0);
  if (allFieldsExist && isBio) {
    return true;
  } else {
    return false;
  }
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    routeConfiguration,
    isAuthenticated,
    onLogout,
    onhandleDeleteAccount,
    onSearchSubmit,
    initialSearchFormValues,
    history,
    userType,
    formatPlaceholder,
    currentUserListing,
    onManageDisableScrolling,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [athletePorfileNotCompleted, setAthletePorfileNotCompleted] = useState(false);
  const [isSportTeam, setIsSportTeam] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Finnish');

  const draftId = '00000000-0000-0000-0000-000000000000';
  const draftSlug = 'draft';

  useEffect(() => {
    setMounted(true);
    const storedLanguage = localStorage.getItem('language');
    if (!localStorage.getItem('rendered')) {
      localStorage.setItem('language', 'Finnish');
      localStorage.setItem('rendered', 'Finnish');
      setLanguage('Finnish');
    }
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);
  const language = typeof window !== 'undefined' && localStorage.getItem('language');
  const dynamicBrandRoute = language === 'Finnish' ? 'brandi' : 'brand';
  const dynamicAthleteRoute = language === 'Finnish' ? 'urheilija' : 'athlete';

  // Function to update the path with dynamic routes
  function updatePath() {
    let currentPath = window.location.pathname; 
    // Check if the current path starts with /en
    if (currentPath.startsWith('/en')) {
      currentPath = currentPath.replace(/^\/en/, ''); // Remove /en if it exists
    } else if((currentPath.startsWith('/brandi'))){ 
      currentPath = '/en' + currentPath;  
    } else if(currentPath.startsWith('/urheilija')){ 
      currentPath = '/en' + currentPath;  
    } 
    // Construct the new URL
    const newUrl = window.location.origin + currentPath;
    return newUrl;
  } 
  const setLanguage = (lg, path) => {
    const language = typeof window !== 'undefined' && localStorage.getItem('language');
    if(language==lg){
      return null;
    }
    typeof window != 'undefined' && localStorage.setItem('language', lg);
    window.location.href = path;
  };
  const handleLanguageChange = lg => {
    if (lg == 'Finnish') {
      let currentPath = updatePath();
      setSelectedLanguage(lg);
      setMenuState(false);
      currentPath = currentPath.replace('brand', 'brandi');
      currentPath = currentPath.replace('athlete', 'urheilija');
      setLanguage(lg, currentPath);
    } else {
      let currentPath = updatePath();
      setSelectedLanguage(lg);
      setMenuState(false);
      currentPath = currentPath.replace('brandi', 'brand');
      currentPath = currentPath.replace('urheilija', 'athlete');
      setLanguage(lg, currentPath);
    }
  }; 
  const { publicData = {}, bio } = currentUser?.attributes?.profile || {};
  const { website, myCompanyInterestValues, phoneNumber, companyName } = publicData || {}; 
  const showCompleteProfileForBrand = !!website && !!myCompanyInterestValues?.length  && !!companyName && !!phoneNumber && !!bio;   
  const user = ensureCurrentUser(currentUser);
  const listing = ensureOwnListing(currentUserListing); 
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  }; 
  // Complete Profile Link
  const completeProfileLink = manageProfileLink => {
    const namedLinkClass = manageProfileLink
      ? classNames(css.menuLink, currentPageClass('ManageListingsPage'))
      : css.menuLink;

    const namedLinkChild = manageProfileLink ? (
      <span className={css.profileSettingsLink}>
        <span className={css.menuItemBorder} />
        {userType === BRAND ? <FormattedMessage id="TopbarDesktop.yourListingsLink" /> : null}
      </span>
    ) : (
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.completeYourProfile" />
      </span>
    );
    // routing according to conditions(new user redirect to create listing and if listing is in draft than redirect to draft listing)
    if (user && user.attributes.profile.publicData?.userType === ATHLETE) {
      if (listing && listing.attributes.state && listing.attributes.state === 'draft') {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            className={namedLinkClass}
            name="EditListingPage"
            params={{ id, slug, type: 'draft', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (!(currentUserHasListings ?? true)) {
        const draftId = '00000000-0000-0000-0000-000000000000';
        const draftSlug = 'draft';
        return (
          <NamedLink
            // className={namedLinkClass}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (
        listing &&
        listing.attributes.state &&
        listing.attributes.state === 'pendingApproval'
      ) {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            className={namedLinkClass}
            name="ListingPageVariant"
            params={{ id, slug, variant: 'pending-approval' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (currentUserListing && manageProfileLink) {
        return (
          <NamedLink
            name="ListingPage"
            className={namedLinkClass}
            params={{
              id: currentUserListing.id.uuid,
              slug: createSlug(currentUserListing.attributes.title),
            }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      }
    }

    return <></>;
  };

  // Edit Profile Link
  const editStylistProfileLink =
    user && userType === ATHLETE && listing.id ? (
      <NamedLink
        name="EditListingPage"
        className={css.profileSettingsLink}
        params={{
          slug: createSlug(listing.attributes.title || ''),
          id: listing.id.uuid,
          type: 'edit',
          tab: 'details',
        }}
      >
        <FormattedMessage id="TopbarDesktop.editProfile" />
      </NamedLink>
    ) : (
      <></>
    );

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;

  const classes = classNames(rootClassName || css.root, className);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const search = (
    <TopbarSearchForm
      className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      routeConfiguration={routeConfiguration}
      initialValues={initialSearchFormValues}
      appConfig={config}
      isKeywordSearchProp={true}
      history={history}
      formatPlaceholder={formatPlaceholder}
    />
  );

  const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
    const currentPageClass = page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    };

    const menuItems = [
      {
        name: 'AccountSettingsPage',
        classNames: classNames(css.menuLink, currentPageClass('AccountSettingsPage')),
        message: <FormattedMessage id="TopbarDesktop.accountSettingsLink" />,
      },
    ];
    if (userType == SPORTTEAM) {
      menuItems.push(
        {
          name: 'ProfileSettingsPage',
          classNames: classNames(css.menuLink, currentPageClass('ProfileSettingsPage')),
          message: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />,
        },
        {
          name: 'ManageListingsPage',
          classNames: classNames(css.menuLink, currentPageClass('ManageListingsPage')),
          message: <FormattedMessage id="TopbarDesktop.yourListingsLinkManager" />,
        }
      );
    }
    if (userType == BRAND) {
      menuItems.push(
        {
          name: 'ProfileSettingsPage',
          classNames: classNames(css.menuLink, currentPageClass('ProfileSettingsPage')),
          message: <FormattedMessage id="TopbarDesktop.profileSettingsLink" />,
        },
        {
          name: 'ManageListingsPage',
          classNames: classNames(css.menuLink, currentPageClass('ManageListingsPage')),
          message: <FormattedMessage id="TopbarDesktop.yourListingsLink" />,
        },
        {
          name: 'FavoriteListingsPage',
          classNames: classNames(css.menuLink, currentPageClass('FavoriteListingsPage')),
          message: <FormattedMessage id="UserNav.favoriteListings" />,
        }
      );
    }
    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          {menuItems.map(item => (
            <MenuItem key={item.name}>
              <NamedLink className={css.yourListingsLink} name={item.name}>
                <span className={css.menuItemBorder} />
                {item.message}
              </NamedLink>
            </MenuItem>
          ))}
          <MenuItem key="EditListingPage">{editStylistProfileLink}</MenuItem>
          <MenuItem key="AthletePage">
            {[ATHLETE, SPORTTEAM].includes(userType) && (
              <a
                className={css.yourListingsLink}
                href={`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/${
                  language === 'Finnish' ? '' : 'en/'
                }${dynamicAthleteRoute}`}
              >
                <FormattedMessage id="TopbarDesktop.athleteHomePage" />
              </a>
            )}
          </MenuItem>
          <MenuItem key="ContactUsPage">
            <NamedLink className={css.yourListingsLink} name="ContactUsPage">
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.campaign" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>

          {/* <MenuItem key="deleteAccount">
            <InlineTextButton rootClassName={classNames(css.logoutButton, css.deleteAccountButton)} onClick={onhandleDeleteAccount}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.deleteAccount" />
            </InlineTextButton>
          </MenuItem> */}
        </MenuContent>
      </Menu>
    );
  };

  const otherLinksMenu = !authenticatedOnClientSide ? (
    <Menu className={css.dropdownMenu}>
      <MenuLabel className={css.menuIcon} isOpenClassName={css.profileMenuIsOpen}>
        <Icons name="menu" />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SignupPage">
          <NamedLink name="SignupPage" className={css.menuLinks}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ContactUsPage">
          <NamedLink className={css.menuLinks} name="ContactUsPage">
            <FormattedMessage id="TopbarDesktop.campaign" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="CampaignPage">
          <NamedLink className={css.menuLinks} name="CampaignDescriptionPage">
            <FormattedMessage id="TopbarDesktop.campaign" />
          </NamedLink>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  const isUserProfileCompleted =
    currentUser && currentUser?.id && checkCurrentUserPublicData(currentUser);

  const checkUserCompletedProfile = tabName => {
    const listing = ensureOwnListing(currentUserListing);
    if (userType == ATHLETE && listing.attributes?.state == 'published') {
      history.push(
        createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: tabName }, {})
      );
    } else if (userType == SPORTTEAM && bio) {
      history.push(
        createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: tabName }, {})
      );
    } else if (userType == ATHLETE) {
      setAthletePorfileNotCompleted(true);
    } else if (userType == SPORTTEAM) {
      setIsSportTeam(true);
    }
  };

  let CampaignsLink;
  let AthletesLink;

  if (isAuthenticated) {
    if ([ATHLETE, SPORTTEAM].includes(userType)) {
      CampaignsLink = (
        <>
          <button
            type="button"
            className={css.searchButton}
            onClick={() => checkUserCompletedProfile(BRANDSEARCH)}
          >
            <span className={css.topbarDesktopLabel}>
              <FormattedMessage id="TopbarDesktop.SponsorSearch" />
            </span>
          </button>
          <button
            type="button"
            className={css.searchButton}
            onClick={() => checkUserCompletedProfile(PROMOTIONSEARCH)}
          >
            <span className={css.topbarDesktopLabel}>
              <FormattedMessage id="TopbarDesktop.PromitionSearch" />
            </span>
          </button>

          {/* Complete Profile Athelte Modal */}
          <Modal
            id="checkUserCompletedProfile"
            isOpen={athletePorfileNotCompleted}
            onClose={() => setAthletePorfileNotCompleted(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <FormattedMessage id="TopbarDesktop.pleaseCompleteProfile" />
              {completeProfileLink()}
            </div>
          </Modal>

          {/* Complete Profile Sport Team Modal */}
          <Modal
            id="checkUserCompletedForSportTeamOrManagerProfile"
            isOpen={isSportTeam}
            onClose={() => setIsSportTeam(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h4>
                <FormattedMessage id="TopbarDesktop.pleaseCompleteProfile" />
              </h4>
              <NamedLink className={css.createNewListingLink} name="ProfileSettingsPage">
                <FormattedMessage id="TopbarDesktop.completeYour" />
              </NamedLink>
            </div>
          </Modal>
        </>
      );
    } else {
      AthletesLink = (
        <>
          {isUserProfileCompleted ? (
            <NamedLink
              className={css.inboxLink}
              name="SearchBasePage"
              params={{ tab: ATHLETESEARCH }}
            >
              <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.marketplace" />
              </span>
            </NamedLink>
          ) : (
            <div onClick={() => setIsProfileModal(true)} className={css.marketplaceLink}>
              <FormattedMessage id="TopbarDesktop.marketplace" />
            </div>
          )}
          <Modal
            id="ProfileRouteModal"
            isOpen={isProfileModal}
            onClose={() => setIsProfileModal(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h4>
                <FormattedMessage id="TopbarDesktop.pleaseCompleteProfile" />
              </h4>
              <NamedLink className={css.createNewListingLink} name="ProfileSettingsPage">
                <FormattedMessage id="TopbarDesktop.completeYour" />
              </NamedLink>
            </div>
          </Modal>
        </>
      );
    }
  }
  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
      />
      {isAuthenticated ? search : null}
      {/* companyName && website && (myCompanyInterestValues && myCompanyInterestValues.length > 0 */}
      {userType === BRAND && !showCompleteProfileForBrand? (
        <NamedLink className={css.inboxLink} name="ProfileSettingsPage">
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.completeYourProfile" />
          </span>
        </NamedLink>
      ) : null}
      <div className={isAuthenticated ? css.navlinksNotAuthenticated : css.navLinks}>
        <div className={css.coursetab}>
          <div className={css.topButton} onClick={() => setMenuState(!menuState)}>
            <IconCard brand="globe" /> {selectedLanguage === 'Finnish' ? 'FI' : 'EN'}
          </div>
          {menuState && (
            <div className={css.courseDropDown}>
              <div className={css.topbarLink} onClick={() => handleLanguageChange('Finnish')}>
                <span className={css.topbarLinkLabel}>FI</span>
              </div>
              <div className={css.topbarLink} onClick={() => handleLanguageChange('English')}>
                <span className={css.topbarLinkLabel}>EN</span>
              </div>
            </div>
          )}
        </div>

        {userType == SPORTTEAM ? (
          <NamedLink
            className={css.inboxLink}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.createListingForSportTeam" />
            </span>
          </NamedLink>
        ) : null}

        {[ATHLETE, SPORTTEAM].includes(userType) || (user && !user?.id) ? null : (
          <NamedLink className={css.inboxLink} name="CampaignDescriptionPage">
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        )}

        {!isAuthenticated ? (
          language === 'Finnish' ? (
            <a
              className={css.topButton}
              href={`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/${dynamicBrandRoute}`}
            >
              <FormattedMessage id="TopbarDesktop.brandHomePage" />
            </a>
          ) : (
            <a
              className={css.topButton}
              href={`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/en/${dynamicBrandRoute}`}
            >
              <FormattedMessage id="TopbarDesktop.brandHomePage" />
            </a>
          )
        ) : null}

        {!isAuthenticated ? (
          language === 'Finnish' ? (
            <a
              className={css.topButton}
              href={`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/${dynamicAthleteRoute}`}
            >
              <FormattedMessage id="TopbarDesktop.athleteHomePage" />
            </a>
          ) : (
            <a
              className={css.topButton}
              href={`${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/en/${dynamicAthleteRoute}`}
            >
              <FormattedMessage id="TopbarDesktop.athleteHomePage" />
            </a>
          )
        ) : null}

        {!isAuthenticated ? (
          <NamedLink name="LoginPage" className={css.topButton}>
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        ) : null}

        {completeProfileLink()}
        {CampaignsLink}
        {AthletesLink}
        {/* {inboxLinkMaybe} */}
        {inboxLink}
        {profileMenuMaybe}
        {/* {signupLinkMaybe} */}
        {/* {loginLinkMaybe} */}
        {otherLinksMenu}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
